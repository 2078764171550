import { LogoutIcon } from "@heroicons/react/outline";
import { UserCircleIcon } from "@heroicons/react/solid";
import Link from "next/link";
import { isLoggedSync } from "utils/storage";
import { useEffect, useMemo, useState } from "react";
import { useSession } from "next-auth/react";

const LoggedButtonsContent = ({
  onLogin,
  logout,
  isLogged: isLoggedNextAuth,
}) => {
  const isLoggedStorage = useMemo(() => isLoggedSync(), []);
  const isLogged = isLoggedStorage || isLoggedNextAuth;

  return (
    <>
      {!isLogged && (
        <button
          onClick={onLogin}
          className="cursor-pointer inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-gray-800 bg-gray-200 hover:bg-gray-100 focus:outline-none "
        >
          SIGN IN
        </button>
      )}
      {isLogged && (
        <Link href="/profile" prefetch={false}>
          <a>
            <UserCircleIcon className="h-8 w-8 text-gray-200 cursor-pointer" />
          </a>
        </Link>
      )}
      {isLogged && (
        <button type="button" onClick={logout}>
          <LogoutIcon className="h-8 w-8 text-gray-200" />
        </button>
      )}
    </>
  );
};

export const LoggedButtons = (props) => {
  const { status } = useSession();
  const isLogged = status === "authenticated";
  const [client, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  if (!client) return null;

  return <LoggedButtonsContent {...props} isLogged={isLogged} />;
};
