import { ToastContainer } from "react-toastify";

import { SessionProvider } from "next-auth/react";

import "styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import Layout from "components/common/Layout";
import { useEffect } from "react";
import { saveUTMParams } from "utils/storage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const MyApp = ({ Component, pageProps, router: { pathname } }) => {
  useEffect(() => {
    saveUTMParams();
  }, []);

  const isDesignPage =
    pathname === "/create/[orderId]/[designId]/design" ||
    pathname === "/stock-shapes/[orderId]/[designId]/design";

  return (
    <>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.NEXT_PUBLIC_PAYPAL_CLIENT_ID,
          currency: "USD",
          intent: "capture",
          components: "buttons",
          disableFunding: ["card", "paylater"],
        }}
      >
        <SessionProvider>
          <Layout hideHeaderOnMobile={isDesignPage}>
            <Component {...pageProps} />
          </Layout>
        </SessionProvider>

        <ToastContainer autoClose={3000} theme="colored" />
      </PayPalScriptProvider>
    </>
  );
};

export default MyApp;
