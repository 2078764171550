const variantButtons = {
  danger: {
    confirm: "px-5 red-button",
    cancel: "px-5 clear-button",
  },
  default: {
    confirm: "px-5 blue-button",
    cancel: "px-5 clear-button",
  },
};

const HeadlessModal = ({
  isOpen,
  onClose,
  title,
  text,
  variant,
  onConfirm,
  onCancel,
}) => {
  if (!isOpen) return null;

  const buttonClasses = variantButtons[variant] || variantButtons.default;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[1002]"
      onClick={handleOverlayClick}
    >
      <div className="relative bg-white rounded-lg shadow-lg p-4 max-w-sm w-full">
        <button
          className="text-gray-600 hover:text-gray-900 text-3xl absolute right-4 top-2"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="text-gray-700 opacity-80 md:text-center space-y-4 text center">
          {title && <h3 className="text-xl mt-5 font-bold ">{title}</h3>}
          <p>{text}</p>
          <div className="flex my-6 w-full justify-around space-x-4">
            <button
              className={buttonClasses.cancel}
              type="button"
              onClick={onCancel}
            >
              No
            </button>
            <button
              className={buttonClasses.confirm}
              type="button"
              onClick={onConfirm}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadlessModal;
