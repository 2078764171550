import { signOut } from "next-auth/react";
import { clearSessionStorage } from "services/Api";
import { clearReduxStore, clearLoggedSync } from "utils/storage";

export default function useLogout() {
  const logout = async () => {
    clearReduxStore();
    clearSessionStorage();
    clearLoggedSync();

    await signOut({
      redirect: true,
      callbackUrl: "/",
    });
  };

  return logout;
}
