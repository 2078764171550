import MmfLogo from "assets/svg/MmfLogo";
import Image from "next/image";
import Link from "next/link";
import GooglePayOption from "assets/images/GooglePayOption.png";
import ApplePayOption from "assets/images/ApplePayOption.png";
import AmericanExpressOption from "assets/images/AmericanExpressOption.png";
import DiscoverOption from "assets/images/DiscoverOption.png";
import DinersClubOption from "assets/images/DinersClubOption.png";
import MasterCardOption from "assets/images/MasterCardOption.png";
import VisaOption from "assets/images/VisaOption.png";
import { Tooltip } from "react-tooltip";
import { ArrowRight } from "lucide-react";
import FacebookIcon from "assets/svg/FacebookIcon.svg";
import InstagramIcon from "assets/svg/InstagramIcon.svg";
import TiktokIcon from "assets/svg/TiktokIcon.svg";
import PinterestIcon from "assets/svg/PinterestIcon.svg";
import LinkedinIcon from "assets/svg/LinkedinIcon.svg";
import XIcon from "assets/svg/XIcon.svg";
import PaintBrush from "assets/svg/PaintBrush.svg";

// const navigation = [
//   { name: "TERMS AND CONDITIONS", href: "/terms" },
//   { name: "FAQ", href: "/faq" },
//   { name: "SHAPES", href: "/shop" },
//   { name: "GUIDELINES", href: "/guidelines" },
//   { name: "PRICING", href: "/shop" },
//   { name: "REVIEWS", href: "/reviews" },
//   { name: "LOGIN", href: "/auth/signin", prefetch: false },
//   { name: "CONTACT US", href: "/contact", prefetch: false },
// ];

const paymentMethods = [
  { label: "Google Pay", image: GooglePayOption, id: "goole_pay" },
  { label: "Apple Pay", image: ApplePayOption, id: "apple_pay" },
  {
    label: "American Express",
    image: AmericanExpressOption,
    id: "american_express",
  },
  { label: "Discover", image: DiscoverOption, id: "discover" },
  { label: "Diners Club", image: DinersClubOption, id: "diners_club" },
  { label: "Master Card", image: MasterCardOption, id: "mastercard" },
  { label: "Visa", image: VisaOption, id: "visa" },
];

const socials = [
  {
    label: "Facebook",
    Icon: FacebookIcon,
    id: "Facebook",
    href: "https://www.facebook.com/getmakemyfreshener/",
  },
  {
    label: "Instagram",
    Icon: InstagramIcon,
    id: "Instagram",
    href: "https://www.instagram.com/makemyfreshener/",
  },
  {
    label: "TikTok",
    Icon: TiktokIcon,
    id: "Tiktok",
    href: "https://www.tiktok.com/@makemyfreshener",
  },
  {
    label: "Pinterest",
    Icon: PinterestIcon,
    id: "Pinterest",
    href: "https://pinterest.com/makemyfreshener",
  },
  {
    label: "LinkedIn",
    Icon: LinkedinIcon,
    id: "Linkedin",
    href: "https://www.linkedin.com/company/makemyfreshener/",
  },
  {
    label: "X",
    Icon: XIcon,
    id: "x",
    href: "https://twitter.com/makemyfreshener",
  },
];

const navigation = {
  shop: [
    { name: "Stock Shape Fresheners", href: "/stock-shapes" },
    { name: "Custom Shape Fresheners", href: "create/custom-details" },
    { name: "Sample Packs", href: "/samples" },
  ],
  learn: [
    { name: "Shapes", href: "/shop" },
    { name: "Pricing", href: "/shop" },
    { name: "Fragrances", href: "/scents" },
  ],
  support: [
    { name: "Contact Us", href: "/contact", prefetch: false },
    { name: "FAQ", href: "/faq" },
    { name: "Guidelines", href: "/guidelines" },
    { name: "Login", href: "/auth/signin", prefetch: false },
  ],
  company: [
    { name: "Reviews", href: "/reviews" },
    { name: "Terms & Conditions", href: "/terms" },
  ],
};

const Footer = () => {
  return (
    <footer className="md:max-w-6xl md:mx-auto mt-6 w-full pb-12 pt-20">
      <section className="px-12 py-8 bg-blue-600 rounded-[40px] flex flex-col md:flex-row items-center justify-center space-x-5">
        <div className="flex items-start flex-col justify-center lg:mb-0 mb-5 mr-auto text-white">
          <h3 className="text-3xl font-semibold">Newsletter</h3>
          <h4 className="mt-2 text-lg">
            Get special offers delivered straight to your inbox!
          </h4>
        </div>
        <input
          className="default-input max-w-[30%] rounded-full px-4 py-2 text-lg"
          placeholder="Enter your email"
        />
        <button className="white-button rounded-[16px]">
          Submit <ArrowRight className="h-6 w-6 ml-3" />
        </button>
      </section>
      <div className="mx-auto blue-dashed-line-lg"></div>
      <section className="px-12 py-8 bg-gray-100 rounded-[40px] flex flex-col md:flex-row items-center justify-center space-x-5">
        <div className="flex items-center justify-center lg:mb-0 mb-5 mr-auto">
          <MmfLogo width={150} height={100} />
        </div>
        <h3 className="text-2xl font-semibold">Custom Rides. Fresh Vibes.</h3>
        <Link href="/shop">
          <a className="blue-button">
            <PaintBrush height={20} width={20} className="mr-2 mt-0.5" />
            Design Now
          </a>
        </Link>
      </section>
      <div className="mx-auto dashed-line-lg"></div>
      <section className="px-12 py-8 max-w-6xl mx-auto bg-gray-100 rounded-[40px] ">
        <nav className="max-w-6xl mx-auto flex flex-row items-start justify-between">
          <ul className="text-black" aria-label="Footer">
            <h4 className="text-lg font-semibold mb-4">Shop</h4>
            {navigation.shop.map((item) => (
              <li key={item.name} className="flex flex-col">
                <Link
                  href={item.href}
                  {...(!item.prefetch && { prefetch: false })}
                >
                  <a className="cursor-pointer text-base text-black hover:text-gray-700 mb-2">
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
          <ul className="text-black" aria-label="Footer">
            <h4 className="text-lg font-semibold mb-4">Learn</h4>
            {navigation.learn.map((item) => (
              <li key={item.name} className="flex flex-col">
                <Link
                  href={item.href}
                  {...(!item.prefetch && { prefetch: false })}
                >
                  <a className="cursor-pointer text-base text-black hover:text-gray-700 mb-2">
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          <ul className="text-black" aria-label="Footer">
            <h4 className="text-lg font-semibold mb-4">Support</h4>
            {navigation.support.map((item) => (
              <li key={item.name} className="flex flex-col">
                <Link
                  href={item.href}
                  {...(!item.prefetch && { prefetch: false })}
                >
                  <a className="cursor-pointer text-base text-black hover:text-gray-700 mb-2">
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          <ul className="text-black" aria-label="Footer">
            <h4 className="text-lg font-semibold mb-4">Company</h4>
            {navigation.company.map((item) => (
              <li key={item.name} className="flex flex-col">
                <Link
                  href={item.href}
                  {...(!item.prefetch && { prefetch: false })}
                >
                  <a className="cursor-pointer text-base text-black hover:text-gray-700 mb-2">
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>

          <section>
            <h4 className="text-lg font-semibold mb-4">We accept:</h4>
            <ul
              className="grid grid-cols-5  gap-y-1 gap-x-2"
              style={{ direction: "rtl" }}
            >
              {paymentMethods.map((paymentMethod) => (
                <li key={paymentMethod.id} id={paymentMethod.id}>
                  <Image
                    src={paymentMethod.image}
                    alt={paymentMethod.label}
                    height={25}
                    width={40}
                  />
                  <Tooltip anchorSelect={`#${paymentMethod.id}`}>
                    {paymentMethod.label}
                  </Tooltip>
                </li>
              ))}
            </ul>
          </section>
        </nav>
        <section className="mt-8 max-w-6xl mx-auto flex flex-row items-center justify-between">
          <div className="text-base text-gray-500 flex ">
            <p className="mr-3 mt-1">
              &copy; {new Date().getFullYear()} Make My Freshener. All Rights
              Reserved.
            </p>
            <Image
              width={25}
              height={25}
              alt="made in USA"
              src="https://flower-manufacturing.s3.amazonaws.com/frontend/makemyfresheners/img/usa.webp"
            />

            <p className="ml-3 mt-1">Made in the USA | $(USD)</p>
          </div>
          <ul className="flex space-x-6 -mb-2">
            {socials.map(({ Icon, ...social }) => (
              <li
                key={social.id}
                id={social.id}
                className="hover:-translate-y-0.5 transition ease-in duration-200"
              >
                <a href={social.href} target="_blank">
                  <Icon alt={social.label} height={40} width={24} />
                  <Tooltip anchorSelect={`#${social.id}`}>
                    {social.label}
                  </Tooltip>
                </a>
              </li>
            ))}
          </ul>
        </section>
      </section>
    </footer>
  );
};

export default Footer;
